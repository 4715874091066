import { lazy } from "react";
import {
  BackgroundContainer
} from "./styles";


const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const OgBlock = lazy(() => import ("../../components/OgVerify/OgBlock"));
;


const OgVerify = () => {
  return (
    <>
    <BackgroundContainer>

        <div style={{
              
        }}>
          <Container>
            <OgBlock/>
          </Container>
        </div>


        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default OgVerify;
